<template lang="html">
  <div>
    <div class="company-header">
      <div>
        <h1>{{ tag }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapState('fieldRecordSystem/navigation', {
      tag: state => state.location.tag
    }),
    ...mapGetters('fieldRecordSystem', [
      'allFieldIdsByTag'
    ]),
    fieldIds () {
      return this.allFieldIdsByTag[this.tag]
    }
  }
}
</script>

<style lang="scss" scoped>
.company-details {
  display: flex;
  flex-direction: column;
}
</style>
